<template>
  <div id="group-builder">
    <ItemBuilder @close="closeBuilder">
      <!-- header -->

      <template #title>
        <template v-if="mode === 'NEW'">Create Group</template>
        <template v-if="mode === 'EDIT'">Edit Group</template>
      </template>

      <template #description>
        <template v-if="mode === 'NEW'">
          Feel free to skip the optional fields and fill them later
        </template>
        <template v-if="mode === 'EDIT'">
          Some of the fields or non-editable
        </template>
      </template>

      <!-- ... -->

      <!-- action bar -->

      <ActionBar
        v-model="tab"
        :is-loading="isLoading"
        @save="mode === 'EDIT' ? updateGroup() : createGroup()"
      />

      <!-- ... -->

      <!-- content -->

      <BaseScrollbar height="calc(100vh - 126px)">
        <div class="row">
          <ValidationObserver ref="form">
            <div class="form">
              <GroupDetails
                ref="GROUP_DETAILS"
                :mode="mode"
                :name.sync="name"
                :users.sync="users"
                :description.sync="description"
                :is-active="tab === 'GROUP_DETAILS'"
                @click="tab = 'GROUP_DETAILS'"
              />
            </div>
          </ValidationObserver>
        </div>
      </BaseScrollbar>

      <!-- ... -->
    </ItemBuilder>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ItemBuilder from "@/components/common/item-builder/ItemBuilder.vue";
import ActionBar from "./components/ActionBar.vue";
import GroupDetails from "./components/GroupDetails.vue";

import { group } from "@/api/factory.js";

export default {
  name: "GroupBuilder",

  components: {
    ItemBuilder,
    ValidationObserver,
    ActionBar,
    GroupDetails,
  },

  props: {
    groupId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      mode: "NEW",
      tab: "GROUP_DETAILS",
      isLoading: false,

      // groupDetails

      name: "",
      description: "",
      users: [],
    };
  },

  watch: {
    groupId: {
      immediate: true,
      handler() {
        if (this.groupId) {
          this.mode = "EDIT";
          this.getGroup();
        }
      },
    },
  },

  methods: {
    closeBuilder() {
      this.$router.back();
    },

    async createGroup() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      this.isLoading = true;

      const payload = {
        name: this.name,
        description: this.description,
        users: this.users,
      };

      const { error } = await group.createGroup(payload);

      if (error) {
        if (error !== "error creating group") {
          this.$alert.warning(error);
        } else {
          this.$alert.error(error);
        }
        this.isLoading = false;
        return;
      }

      this.closeBuilder();
      this.$alert.success("New group created succesfully");
    },

    async getGroup() {
      const { error, payload } = await group.getGroup(this.groupId);

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.name = payload.name;
      this.description = payload.description;
      this.users = payload.users?.map((user) => user.id);
    },

    async updateGroup() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }

      this.isLoading = true;

      const payload = {
        name: this.name,
        description: this.description,
        users: this.users,
      };

      const { error } = await group.updateGroup(this.groupId, payload);

      this.isLoading = false;

      if (error) {
        if (error !== "error updating group") {
          this.$alert.warning(error);
        } else {
          this.$alert.error(error);
        }
        return;
      }

      this.closeBuilder();
      this.$alert.success("Group updated successfully");
    },
  },
};
</script>

<style lang="scss" scoped>
#group-builder {
  height: 100vh;
}
</style>
